<template>
  <auth-card :heading-text="$t('authentication.verify-email-heading')"
    :btn-text="$t('authentication.verify-email-button')"
    :show-close-btn="true"
    @btn-click="resendEmail">
    <template #description>
      <p class="font-weight-500">
        {{ $t('authentication.verify-email-subheading') }}
      </p>
      <p>{{ $t('authentication.verify-email-description') }}</p>
    </template>
  </auth-card>
</template>

<script>
import { mapMutations } from 'vuex'
import AuthCard from '@/components/ui-components/AuthCard.vue'
import sdk from '@megaport/api-sdk'

export default {
  name: 'SignupVerifyEmail',

  components: {
    'auth-card': AuthCard,
  },

  methods: {
    ...mapMutations('Notifications', ['notifyBad', 'notifyGood']),

    /**
     * Retrigger confirmation email
     */
    resendEmail() {
      const email = localStorage.getItem('_username')

      sdk.instance.retriggerConfirmationEmail(email)
        .then(() => {
          this.notifyGood({
            title: this.$t('authentication.verify-email-resent-successful'),
          })
        })
        .catch(error => {
          if (!error.handled) {
            this.notifyBad({
              title: this.$t('authentication.verify-email-resent-unsuccessful'),
            })
          }
        })
    },
  },
}
</script>
